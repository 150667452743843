import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MonitorService } from '../monitor.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(public monitorService: MonitorService, private _router: Router) {}

  ngOnInit(): void {
    let defaultMenu = this.monitorService.menu[0];

    if (defaultMenu.routerLink) this._router.navigate([defaultMenu.routerLink]);
    else this._router.navigate([defaultMenu.subMenu[0].routerLink]);
  }
}
