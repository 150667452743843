<!-- <app-nav-bar [options]="options"></app-nav-bar> -->

<global-header [title]="'SISTEMA DE GESTIÓN DE SINIESTROS'"></global-header>

<navbar-blue [menu]="monitorService.menu"></navbar-blue>

<div class="internal-container">
  <router-outlet></router-outlet>
</div>

<footer-sura></footer-sura>
