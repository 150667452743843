<header class="header">
  <div class="logo">
    <img src="assets/img/LogoSURA.svg" alt="" width="130" />
  </div>

  <h3 class="text-secondary">{{ title }}</h3>

  <div>
    <div class="responsive-menu d-flex">
      <!-- <div
        *ngFor="let item of options"
        [routerLink]="item.route"
        class="menu-link d-none-sm"
      >
        {{ item.title }}
      </div> -->
      <div class="flex-container-nowrap responsive-menu">
        <label class="text-xsmall d-flex-sm mr-1" style="text-align: right"
          >usuario@sura.com</label
        >
        <img alt="" src="assets/img/user.png" (click)="switchDropdown()" />

        <div
          class="dropdown"
          id="dropdown"
          [ngClass]="{ active: showDropdown }"
        >
          <p (click)="singOut()">Cerrar Sesión</p>
        </div>
      </div>

      <div class="burger-menu" (click)="switchDropdownBurger()">
        <i class="fas fa-bars"></i>
        <div
          class="dropdown"
          id="dropdownBurger"
          [ngClass]="{ active: showDropdownBurger }"
        >
          <p *ngFor="let item of options" [routerLink]="item.route">
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- <div class="menu-sm d-none d-flex-sm">
  <div class="responsive-menu-sm d-none d-flex-sm">
    <div
      *ngFor="let item of options"
      [ngClass]="{ active: item.title == service.selectedMenu }"
      [routerLink]="item.route"
      class="menu-link"
    >
      {{ item.title }}
    </div>
  </div>
</div> -->
