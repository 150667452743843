import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar-monitor',
  templateUrl: './nav-bar-monitor.component.html',
  styleUrls: ['./nav-bar-monitor.component.scss'],
})
export class NavBarMonitorComponent implements OnInit {
  @Input() menu: Array<any>;
  activeOption: string;
  showDropdownBurger: boolean = false;
  showDropdown: boolean = false;
  selected: any;

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.selected = this.menu[0];
    this.router.navigate([this.selected.route]);

    this.dropDownListenerBurger();
    this.dropDownListener();
  }

  switchDropdownBurger() {
    this.showDropdownBurger = !this.showDropdownBurger;
  }

  switchDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  dropDownListenerBurger() {
    document.addEventListener('click', (evt) => {
      const dropdown = document.getElementById('dropdownBurger');
      let targetElement: any = evt.target;

      if (targetElement.closest('.burger-menu')) return;

      do {
        if (targetElement == dropdown) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      // console.log("Clicked outside!");
      this.showDropdownBurger = false;
    });
  }

  dropDownListener() {
    document.addEventListener('click', (evt) => {
      const dropdown = document.getElementById('dropdown');
      let targetElement: any = evt.target;

      if (targetElement.closest('.responsive-menu')) return;

      do {
        if (targetElement == dropdown) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      // console.log('Clicked outside!');
      this.showDropdown = false;
    });
  }

  singOut() {
    this.showDropdown = false;

    this.authService.logout().subscribe(
      (response) => {
        console.log(response);
        localStorage.removeItem('userToken');
        this.router.navigate(['/']);
      },
      (error) => {
        console.log(error);
        localStorage.removeItem('userToken');
        this.router.navigate(['/']);
      }
    );
  }

  changeSelected(selected) {
    console.log(selected);

    this.selected = selected;
  }
}
