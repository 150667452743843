<div class="card-info" [ngStyle]="{ 'background-color': config.color }">
  <div class="left">
    <p style="font-size: 28px">{{ config.number }}</p>
    <p class="label-status">
      {{ config.text }}
    </p>
  </div>
  <!-- <div class="right">
    <i [class]="config.icon" [ngStyle]="{ color: config.color_icon }"></i>
  </div> -->
</div>
