import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { MonitorService } from '../monitor.service';

@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanActivate {
  constructor(public monitorService: MonitorService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let menu = route.data[0];
    let subMenu = route.data[1];

    let arr = this.monitorService.menu;

    let menuFinded;
    let subMenuFinded;

    // solo para dev
    return true;

    if (arr) menuFinded = arr.find((o) => o.menu === menu);
    else {
      this.router.navigate(['/login']);
      return false;
    }

    if (menuFinded) {
      if (!subMenu) return true;
      else {
        subMenuFinded = menuFinded.subMenu.find((o) => o.label === subMenu);

        if (subMenuFinded) return true;
        else {
          this.router.navigate(['/login']);
          return false;
        }
      }
    } else {
      {
        this.router.navigate(['/login']);
        return false;
      }
    }
  }
}
