import { CardInfoComponent } from './card-info/card-info.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { PortalSeguimientoComponent } from './portal-seguimiento/portal-seguimiento.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StateBarComponent } from './state-bar/state-bar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../../src/app/shared/shared.module';
import { NavBarMonitorComponent } from './nav-bar-monitor/nav-bar-monitor.component';
import { NavBarNewComponent } from './nav-bar-new/nav-bar-new.component';
import { FooterComponent } from './footer/footer.component';
import { FormularioAgenteComponent } from './formulario-agente/formulario-agente.component';
import { FormularioDireccionComponent } from './formulario-direccion/formulario-direccion.component';

@NgModule({
  declarations: [
    CardInfoComponent,
    NavBarComponent,
    PortalSeguimientoComponent,
    StateBarComponent,
    NavBarMonitorComponent,
    NavBarNewComponent,
    FooterComponent,
    FormularioAgenteComponent,
    FormularioDireccionComponent,
  ],
  imports: [
    CommonModule,
    NgxSmartModalModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  exports: [
    CardInfoComponent,
    NavBarComponent,
    NgxSmartModalModule,
    FormsModule,
    PortalSeguimientoComponent,
    NavBarMonitorComponent,
    NavBarNewComponent,
    FooterComponent,
    FormularioAgenteComponent,
    FormularioDireccionComponent,
  ],
})
export class SharedMonitorModule {}
