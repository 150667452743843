import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-state-bar-monitor',
  templateUrl: './state-bar.component.html',
  styleUrls: ['./state-bar.component.scss']
})
export class StateBarComponent implements OnInit {
  @Input() header: Array<any>;
  @Input() data: any;
  @Input() firstRow: number;
  @Input() type: string;

  constructor() {
    this.firstRow = 4;
    this.type = 'vigente';
    this.header = [{title: 'Titular', value: 'name'}, {title: 'Número de póliza', value: 'number'}, {
      title: 'Fecha último pago',
      value: 'date'
    }];
    this.data = {name: 'Juan José Montenegro Ramírez', number: '9341', date: 'dd/mm/yy'};
  }

  ngOnInit(): void {
  }

}
