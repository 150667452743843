<header class="header">
  <div class="logo">
    <img src="assets/img/LogoSURA.svg" alt="" width="130" />
  </div>

  <div>
    <div class="responsive-menu d-flex">
      <div
        *ngFor="let item of menu"
        [ngClass]="{ active: item == selected }"
        [routerLink]="item.route"
        class="menu-link d-none-sm"
        (click)="changeSelected(item)"
      >
        {{ item.label }}
      </div>
      <div class="flex-container-nowrap responsive-menu">
        <label
          class="text-xsmall d-none d-flex-sm mr-1"
          style="text-align: right;"
          >usuario@sura.com</label
        >
        <img alt="" src="assets/img/user.png" (click)="switchDropdown()" />

        <div
          class="dropdown"
          id="dropdown"
          [ngClass]="{ active: showDropdown }"
        >
          <p (click)="singOut()">Cerrar Sesión</p>
        </div>
      </div>

      <div class="burger-menu" (click)="switchDropdownBurger()">
        <i class="fas fa-bars"></i>
        <div
          class="dropdown"
          id="dropdownBurger"
          [ngClass]="{ active: showDropdownBurger }"
        >
          <p *ngFor="let item of menu" [routerLink]="item.route">
            {{ item.label }}
          </p>
        </div>
      </div>
    </div>
  </div>
</header>
