import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent implements OnInit {

  @Input() config: any;
  constructor() {
    this.config = {
      number: '2',
      text: 'Solicitudes Nuevas',
      color: '#ffa726',
      icon: 'fas fa-file-medical',
      color_icon: '#e68900'
    }
  }

  ngOnInit(): void {
  }

}
