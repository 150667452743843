import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { AppConstants } from 'src/app/constants';
import { cleanObject } from 'src/functions/common-functions';

@Injectable({
  providedIn: 'root',
})
export class VidaService {
  _baseURL: string;

  constructor(private http: HttpClient, private router: Router) {
    this._baseURL = AppConstants.apiBienestar;
  }

  selectedMenu: string;
  userRole = 1;
  userName = 'JJCAMARQ';

  setSelectedMenu(menu) {
    this.selectedMenu = menu;
  }

  getUsersForReasign(): Observable<any> {
    const url = `${this._baseURL}/monitor/solicitudes`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getSolicitudes(): Observable<any> {
    const url = `${this._baseURL}/monitor/solicitudes`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getTramites(p): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/tramites`;

    let params = cleanObject(p);

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getMisTramites(p): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/mis-tramites`;

    let params = cleanObject(p);

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getMySolicitudes(): Observable<any> {
    const url = `${this._baseURL}/monitor/solicitudes?ususario=${this.userName}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  aceptarRechazar(json): Observable<any> {
    const url = `${this._baseURL}/monitor/solicitud/aceptar`;
    let body = new FormData();
    body.append('reclamacion_id', json.reclamacion_id);
    body.append('acepta', json.acepta);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  reasignar(json): Observable<any> {
    const url = `${this._baseURL}/monitor/solicitud/reasignar`;
    let body = new FormData();
    body.append('reclamacion_id', json.reclamacion_id);
    body.append('usuario', json.usuario);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  reasignarV1(json): Observable<any> {
    console.log(json);

    let body = {
      id: json.reclamacion_id,
      asignar: json.usuario,
    };
    const url = `${this._baseURL}/v1/monitor/vida/reasignar`;
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    const route = ['/vida', ...commands];
    return this.router.navigate(route, extras);
  }
  getDictaminadores(): Observable<any> {
    const url = `${this._baseURL}/users/dictaminadores/vida`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  getToken() {
    return localStorage.getItem('accessToken');
  }

  getStatus(): Observable<any> {
    const url = `${this._baseURL}/status-tramites/listar`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getStatusV1(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/status-tramite`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getIndicadores(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/indicadores-general`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getIndicadoresTramites(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/indicadores`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getAsignados(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/usuarios/vida/asignado`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getTramiteById(idTramite): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/tramites/${idTramite}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  finalizarTramite(id): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/tramites/${id}/actualizar-fn`;
    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  getRoles(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/usuarios/roles/vida`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getUsuarios(roles): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/usuarios/vida`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: roles,
      })
      .pipe(map((data) => data));
  }

  getStatusTramite(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/status-tramite`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getReporte(params): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/vida/reporte?`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getAgentes(search): Observable<any> {
    const url = `${this._baseURL}/catalogos/agentes?search=${search}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getGrupos(search): Observable<any> {
    const url = `${this._baseURL}/catalogos/grupos?search=${search}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getSubGrupos(codes, search?): Observable<any> {
    if (!search) search = '';

    const url = `${this._baseURL}/catalogos/subgrupos?codes=${codes}&search=${search}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getOficinas(): Observable<any> {
    const url = `${this._baseURL}/catalogos/oficinas`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getRamos(): Observable<any> {
    const url = `${this._baseURL}/catalogos/ramos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
