<global-header [title]="'SISTEMA DE GESTIÓN DE SINIESTROS'"></global-header>

<navbar-blue [menu]="monitorService.menu"> </navbar-blue>

<div class="internal-container">
  <div style="text-align: center">
    <p class="title-one text-bold">Catálogos SE</p>
  </div>

  <div style="padding: 0 20%">
    <form class="text-secondary text-xsmall mt-5" [formGroup]="form">
      <div class="form-group">
        <label class="text-secondary">Área de negocio</label>
        <select
          name="motivo"
          class="form-control"
          formControlName="area"
          (change)="setCatalogos()"
        >
          <option selected disabled [value]="null">
            Seleccione área de negocio
          </option>
          <option *ngFor="let area of arrayAreas" [value]="area.code">
            {{ area.desc }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label class="text-secondary">Tipo de catálogo</label>
        <select name="motivo" class="form-control" formControlName="catalogo">
          <option selected disabled [value]="null">
            Seleccione tipo de catálogo
          </option>
          <option *ngFor="let item of arrayCatalogos" [value]="item.code">
            {{ item.desc }}
          </option>
        </select>
      </div>
    </form>

    <div class="mt-2">
      <file-manager
        *ngFor="let item of arrayDocs; let i = index"
        [fileInfo]="item.fileInfo"
        [status]="item.status"
        [colorStatus]="item.colorStatus"
        [index]="i"
        [first]="i === 0 ? true : false"
        [actions]="item.actions"
        (fileManagerEvent)="fileManagerEvent($event, i)"
      >
      </file-manager>
    </div>
  </div>

  <div class="error text-danger mt-2">
    {{ errorMsg }}
  </div>

  <div class="bottom">
    <button
      *ngIf="!waitingForApi"
      class="btn btn-primary"
      [disabled]="!form.valid"
      (click)="guardar()"
    >
      Guardar
    </button>

    <div *ngIf="waitingForApi" style="text-align: center">
      <div id="loading"></div>
    </div>
  </div>
</div>

<footer-sura></footer-sura>

<ngx-smart-modal
  #confirmCatalogCreaded
  identifier="confirmCatalogCreaded"
  [closable]="false"
  [dismissable]="false"
  [customClass]="'nsm-centered modal-reject-contrac'"
  class="text-center"
>
  <div class="container-modal">
    <p class="mb-2">{{ confirmMsg }}</p>
    <button (click)="finish()" class="btn btn-primary">aceptar</button>
  </div>
</ngx-smart-modal>
