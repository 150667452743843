import { Component } from '@angular/core';
import { ApiService } from './api.service';
import { MonitorService } from './monitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Monitor-BC';

  constructor(
    public apiService: ApiService,
    public monitorService: MonitorService
  ) {
    localStorage.setItem('app', 'monitor');
  }

  ngOnInit(): void {
    if (this.apiService.getToken())
      this.apiService.aseguradoDetalle().subscribe((response) => {
        let menu = [];

        for (let index = 0; index < response.length; index++) {
          const element = response[index];

          let subMenu = [];

          for (let index = 0; index < element.subMenu.length; index++) {
            const element2 = element.subMenu[index];

            let menuString = this.cleanString(element.menu);
            let subMenuString = this.cleanString(element2);

            let routerLink = '/' + menuString + '/' + subMenuString;

            let obj = {
              label: element2,
              routerLink,
            };

            subMenu.push(obj);
          }

          menu.push({
            menu: element.menu,
            subMenu,
          });
        }

        this.monitorService.menu = [...menu];
      });
  }

  cleanString(s) {
    return s
      .replaceAll(' ', '-')
      .replaceAll('á', 'a')
      .replaceAll('é', 'e')
      .replaceAll('í', 'i')
      .replaceAll('ó', 'o')
      .replaceAll('ú', 'u')
      .toLowerCase();
  }
}
