<div>
  <div class="cont-data">
    <div *ngFor="let dat of header | slice:0:firstRow;let odd = odd" [ngClass]="{
    'd-block':dat.mobile,
    'd-none-sm':!dat.mobile,
    'cont-item':!odd,
    'cont-item2':odd}"
         class="">
      <label class="text-value">{{data[dat.value] == 0 ? 'N/A' : data[dat.value]}}</label>
      <p class="text-title">{{dat.title}}</p>
    </div>
  </div>
  <div class="cont-data2">
    <div *ngFor="let dat of header | slice:firstRow" [ngClass]="{'d-block':dat.mobile,'d-none-sm':!dat.mobile}"
         class="cont-item">
      <label
        class="text-value">{{dat.prefix ? dat.prefix : ''}}{{data[dat.value] == 0 ? 'N/A' : data[dat.value]}}</label>
      <p class="text-title">{{dat.title}}</p>
    </div>
  </div>
</div>

