import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';
import { cleanObject, getFormattedDate } from 'src/functions/common-functions';

@Injectable({
  providedIn: 'root',
})
export class ApiCabinaService {
  _baseURL: string;
  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.apiBienestar;
  }
  getToken() {
    return localStorage.getItem('userToken');
  }
  getBanks(): Observable<any> {
    const url = `${this._baseURL}/catalogos/bancos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getNacionalidades(): Observable<any> {
    const url = `${this._baseURL}/catalogos/nacionalidades`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getSexo(): Observable<any> {
    const url = `${this._baseURL}/catalogos/sexos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getLocalidad(codigoPostal: any): Observable<any> {
    const url = `${this._baseURL}/catalogos/localidad?codigo_postal=${codigoPostal}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getColonias(codigoPostal: any): Observable<any> {
    const url = `${this._baseURL}/catalogos/colonias?codigo_postal=${codigoPostal}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getSiglas(): Observable<any> {
    const url = `${this._baseURL}/catalogos/siglas-domicilio`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getDireccionV1(poliza, ramo, oficina, nmsituac): Observable<any> {
    // oficina = 1;
    // ramo = 420;
    // poliza = 200024134;
    // nmsituac = 1;

    const url = `${this._baseURL}/v1/polizas/asegurado/domicilios?oficina=${oficina}&ramo=${ramo}&poliza=${poliza}&nmsituac=${nmsituac}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  getRespaldos(): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-cristales/respaldos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadFoto(file, cristal_siniestro_id, tipo_foto): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-cristales/fotos`;

    var formData = new FormData();

    formData.append('cristalSiniestroId', cristal_siniestro_id);
    formData.append('tipoFoto', tipo_foto);
    formData.append('foto', file);

    console.log('FormData', formData);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadFactura(cristal_siniestro_id, factura): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-cristales/facturas`;

    var formData = new FormData();

    formData.append('cristalSiniestroId', cristal_siniestro_id);
    formData.append('factura', factura);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearSiniestroV1(body): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/cristales/crear `;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listarDocumentos(): Observable<any> {
    const url = `${this._baseURL}/v1/documentos-siniestros-cristales/listar`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  subirDocumento(obj): Observable<any> {
    let body = new FormData();
    body.append('documentosCristalesId', obj.documentosCristalesId);
    body.append('cristalesSiniestroId', obj.cristalesSiniestroId);
    body.append('file', obj.file);

    const url = `${this._baseURL}/v1/documentos-siniestros-cristales/subir-documento`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  createPersonaContacto(json): Observable<any> {
    const url = `${this._baseURL}/v1/personas/cristales/contacto`;

    return this.http
      .post(url, json, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  enviarAlea(id): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-cristales/enviar-alea/${id}`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  generarCarta(id) {
    const url = `${this._baseURL}/v1/siniestro-cristales/generar-pdf/${id}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getOcupaciones(): Observable<any> {
    const url = `${this._baseURL}/catalogos/ocupaciones`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getIdentificaciones(): Observable<any> {
    const url = `${this._baseURL}/catalogos/identificaciones`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getCuentasBancarias(cdperson) {
    const url = `${this._baseURL}/v1/personas/cuentas-bancarias/${cdperson}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearPersona(body, type): Observable<any> {
    let url = `${this._baseURL}/v1/personas/cristales/fisica`;
    if (type === 'moral') url = `${this._baseURL}/v1/personas/cristales/moral`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearCuentaBancaria(obj): Observable<any> {
    const url = `${this._baseURL}/v1/personas/cuentas-bancarias`;

    let body = new FormData();
    body.append('file', obj.file);
    body.append('personaId', obj.personaId);
    body.append('banco', obj.banco);
    body.append('clabe', obj.clabe);

    if (obj.fecha) body.append('fecha', obj.fecha);

    body.append('cuenta', obj.cuenta);

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  agregarCuenta(body): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-cristales/agregar-cuenta`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  subirArchivosPersona(obj): Observable<any> {
    const url = `${this._baseURL}/v1/personas/archivos`;

    let body = new FormData();
    body.append('ide', obj.ide);
    body.append('compDomicilio', obj.compDomicilio);
    body.append('personaId', obj.personaId);

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getAseguradoByCdperson(cdperson): Observable<any> {
    const url = `${this._baseURL}/v1/personas/cristales/datos/asegurado/${cdperson}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getAseguradoV1(obj, type): Observable<any> {
    let url;

    // obj = {
    //   rfc: 'LOMB770812',
    //   oficina: '1',
    //   ramo: '420',
    //   poliza: '200024134',
    //   fechaOcurrencia: '2012-05-27',
    //   primerNombre: 'BRISSA',
    //   primerApellido: 'LOPEZ',
    // };

    // obj = {
    //   nombreEmpresa: 'VITRO CANCELES, S.A. DE C.V.',
    //   fechaNacimiento: '1986-04-25',
    //   rfc: 'VCA860425579',
    //   oficina: '1',
    //   ramo: '430',
    //   poliza: '31701',
    //   fechaOcurrencia: '2015-03-27',
    // };

    if (type == 'fisica')
      url = `${this._baseURL}/v1/monitor/busqueda/cristales/asegurado/fisico`;

    if (type == 'moral')
      url = `${this._baseURL}/v1/monitor/busqueda/cristales/asegurado/moral`;

    delete obj['select'];
    if (obj.fechaOcurrencia)
      obj.fechaOcurrencia = getFormattedDate(obj.fechaOcurrencia, 'yyyy-mm-dd');

    if (obj.fechaNacimiento)
      obj.fechaNacimiento = getFormattedDate(obj.fechaNacimiento, 'yyyy-mm-dd');

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: cleanObject(obj),
      })
      .pipe(map((data) => data));
  }
}
