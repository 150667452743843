import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  _baseURL: string;
  loginURL: string;

  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.apiBienestar;
  }

  getToken() {
    return localStorage.getItem('userToken');
  }

  aseguradoDetalle(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/menu`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
