import { Component, OnInit } from '@angular/core';
import { MonitorService } from '../monitor.service';

@Component({
  selector: 'app-alianza',
  templateUrl: './alianza.component.html',
  styleUrls: ['./alianza.component.scss'],
})
export class AlianzaComponent implements OnInit {
  constructor(public monitorService: MonitorService) {}

  ngOnInit(): void {}
}
