import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _baseURL: string;
  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.bienestarAuth;
  }

  loginUser(form) {
    const url = `${this._baseURL}/auth/user/login`;
    let body = new FormData();
    body.append('email', form.email);
    body.append('password', form.password);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }
  setToken(token) {
    localStorage.setItem('accessToken', 'Bearer ' + token);
    localStorage.setItem('userToken', 'Bearer ' + token);
  }
  getToken() {
    return localStorage.getItem('accessToken');
  }

  logout(): Observable<any> {
    const url = `${this._baseURL}/auth/user/logout`;
    localStorage.removeItem('current_user');
    return this.http
      .post(url, null, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
