import { Component, OnInit } from '@angular/core';
import { MonitorService } from '../monitor.service';

@Component({
  selector: 'app-configurador-tramite',
  templateUrl: './configurador-tramite.component.html',
  styleUrls: ['./configurador-tramite.component.scss']
})
export class ConfiguradorTramiteComponent implements OnInit {

  constructor(public monitorService: MonitorService) { }
  
  ngOnInit(): void {
  }

}
