<div *ngIf="state == 0">
  <ngx-smart-modal
    #portalModal
    [customClass]="'nsm-centered'"
    class="text-center"
    identifier="portalModal"
  >
    <div class="text-medium" style="padding: 50px">
      <p>
        Lo sentimos, puede <br />
        consultar el estado de su <br />
        trámite a través del <br />
        0800SURA
      </p>
    </div>
    <div>
      <button (click)="closeModal()" class="btn btn-primary">OK</button>
    </div>
  </ngx-smart-modal>
</div>
<div *ngIf="state == 1" class="portal-container-part-2">
  <div class="top-banner">
    <app-breadcrumbs
      *ngIf="breadcrumbs"
      [breadcrumbs]="breadcrumbs"
    ></app-breadcrumbs>
    <div class="title">
      <div class="options text-center">
        <label class="text-center text-large text-bold">{{
          typeProcess
        }}</label>
        <label class="text-small d-flex d-none-sm">Tipo de Trámite</label>
        <label class="text-small d-none d-flex-sm">Tipo de Trámite</label>
      </div>
      <div class="options2">
        <button
          *ngIf="!desactivado"
          class="btn btn-outline-danger flex-inline mr-4"
        >
          <i class="fas fa-times-circle d-none-sm text-epic"></i>
          Rechazar Trámite
        </button>
        <button
          *ngIf="!desactivado"
          (click)="nuevoTramite()"
          class="btn btn-outline-primary flex-inline"
        >
          <i class="fas fa-check-circle d-none-sm text-epic"></i>
          Aceptar Trámite
        </button>
        <div *ngIf="desactivado" class="special-alert text-warning text-bold">
          <i class="fas fa-reply mr-3"></i>
          DEVOLUCIÓN ADMINISTRATIVA
        </div>
      </div>
    </div>
    <app-state-bar-monitor
      [data]="data"
      [header]="headers"
      [firstRow]="4"
    ></app-state-bar-monitor>
  </div>
  <div class="p-lg-0 p-sm-10">
    <app-tabs
      (selected)="tabSelected = $event"
      [options]="[
        'General',
        'Documentación',
        'Facturas y XML',
        'Coberturas',
        'Tabulador',
        'Información Bancaria',
        'Contacto'
      ]"
    ></app-tabs>
    <div *ngIf="tabSelected == 'General'" class="container-body flex-sm-revert">
      <div class="time-line-container w-lg-70 w-sm-100">
        <app-time-line [last]="true" [title]="dataTimeLine[0]">
          <div class="text-secondary bg-info pl-5 mb-3">
            2:36 PM - Creación de trámite
          </div>
        </app-time-line>
      </div>
      <div class="right-container">
        <div class="lat-card text-center">
          <p>Total Solicitado</p>
          <p class="text-medium text-bold-medium mt-1">$123.567,25</p>
        </div>
      </div>
    </div>
    <div *ngIf="tabSelected == 'Documentación'" class="container-body">
      <div
        *ngIf="desactivado"
        class="d-none-sm text-center w-100"
        style="display: flex; justify-content: center; align-items: center"
      >
        <p class="text-center text-large text-bold mt-2">
          Se está actualizando la documentación, por favor espere
        </p>
      </div>
      <div *ngIf="desactivado" class="d-none d-block-sm">
        <div class="text-center">
          <h4>Se está actualizando la documentación</h4>
          <h4>Por favor espere.</h4>
        </div>
      </div>
      <div
        *ngIf="!desactivado"
        class="d-block d-none-sm"
        style="width: 100%; flex-wrap: wrap"
      >
        <div *ngIf="arrayDocsUpdated.length > 0" class="doc-table">
          <div class="doc-header">
            <div class="doc-head-item text-bold text-medium">
              Documentos Actualizados por el cliente
            </div>
            <div class="doc-head-item2 text-bold text-medium">
              Motivo de Devolución Administrativa
            </div>
          </div>
          <div
            *ngFor="let item of arrayDocsUpdated; let i = index"
            class="doc-item"
          >
            <div class="doc-label">
              <div class="flex-container w-100">
                <div>
                  <label
                    class="text-medium text-bold text-primary label-width"
                    style="width: 200px"
                    >{{ item.nombre }}</label
                  >
                  <label> Sample.pdf - dd/mm/yyyy </label>
                </div>
                <i class="fas fa-download"></i>
              </div>
            </div>
            <div class="doc-choice">
              <select class="form-control">
                <option>Seleccione una opción...</option>
              </select>
            </div>
          </div>
        </div>
        <div class="doc-table">
          <div class="doc-header">
            <div class="doc-head-item text-bold text-medium">
              Documentos Asociados al Trámite
            </div>
            <div class="doc-head-item2 text-bold text-medium">
              {{
                arrayDocsUpdated.length > 0
                  ? " "
                  : "Motivo de Devolución Administrativa"
              }}
            </div>
          </div>
          <div
            *ngFor="let item of arrayDocsAsegurados; let i = index"
            class="doc-item"
          >
            <div class="doc-label">
              <div class="flex-container w-100">
                <div style="display: flex; width: 100%">
                  <label
                    class="text-bold text-primary label-width"
                    style="flex: 0.3"
                    >{{ item.nombre }}</label
                  >
                  <label> Sample.pdf - dd/mm/yyyy </label>
                </div>
                <i
                  (click)="openFile(i)"
                  class="fa-search fas search doc-action"
                ></i>
                <!-- <i (click)="getFile(i)" class="fas fa-download doc-action"></i> -->
                <i class="fas fa-download doc-action"></i>
              </div>
            </div>
            <div class="doc-choice">
              <select class="form-control">
                <option>Seleccione una opción...</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <a href="https://www.gob.mx/ActaNacimiento/validacionActa/"
            >Validación externa Actas de Nacimiento</a
          >
        </div>

        <div class="verifyContainer">
          <p class="text-bold">Verificación de cédula médica</p>

          <div class="searchContainer">
            <input
              #cedula
              class="form-control"
              style="max-width: 280px"
              (keyup.enter)="buscarCedula(cedula.value)"
            />
            <i
              *ngIf="!sending"
              class="fas fa-search"
              (click)="buscarCedula(cedula.value)"
            ></i>
            <div *ngIf="sending" id="loading"></div>
          </div>
          <p>Introduzca la cédula médica</p>

          <small *ngIf="!cedulaValida" class="text-danger"
            >Cédula médica no válida</small
          >

          <div class="results" *ngIf="cedulasEncontradas">
            <p style="margin: 25px 0px" class="text-bold">
              Resultados Obtenidos
            </p>

            <app-table-basic
              [tableHeaders]="cedulasTableHeaders"
              [rows]="arrayCedulas"
              class="table"
            ></app-table-basic>
          </div>
        </div>
        <button (click)="devolver()" class="btn btn-primary">
          Efectuar Devolución Administrativa
        </button>
      </div>
      <div *ngIf="!desactivado" class="d-none d-block-sm">
        <div *ngIf="arrayDocsUpdated.length > 0" class="d-none d-block-sm">
          <p class="text-special text-bold">
            Documentos Actualizados por el cliente
          </p>
          <div
            *ngFor="let item of arrayDocsUpdated; let i = index"
            class="card"
          >
            <div class="form-group card-body d-block-sm">
              <div class="flex-container mb-2">
                <div class="d-block">
                  <label class="text-primary mb-1" style="font-weight: bold">{{
                    item.nombre
                  }}</label
                  ><br />
                  <label> Sample.pdf - dd/mm/yyyy </label>
                </div>
                <div>
                  <i class="fas fa-download" style="float: right"></i>
                </div>
              </div>
              <select class="form-control">
                <option>Seleccione una opción...</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-none d-block-sm">
          <p class="text-special text-bold">Documentos Asociados al Trámite</p>
          <div
            *ngFor="let item of arrayDocsAsegurados; let i = index"
            class="card"
          >
            <div class="form-group card-body d-block-sm">
              <div class="flex-container mb-2">
                <div class="d-block">
                  <label class="text-primary mb-1" style="font-weight: bold">{{
                    item.nombre
                  }}</label
                  ><br />
                  <label> Sample.pdf - dd/mm/yyyy </label>
                </div>
                <div style="display: flex; font-size: 18px">
                  <i class="fas fa-search mr-1"></i>
                  <i
                    (click)="getFile(i)"
                    class="fas fa-download"
                    style="float: right"
                  ></i>
                </div>
              </div>
              <select class="form-control">
                <option>Seleccione una opción...</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <a href="https://www.gob.mx/ActaNacimiento/validacionActa/"
            >Validación externa Actas de Nacimiento</a
          >
        </div>

        <div class="verifyContainer">
          <p class="text-bold">Verificación de cédula médica</p>

          <div class="searchContainer">
            <input
              #cedula
              class="form-control"
              style="max-width: 280px"
              (keyup.enter)="buscarCedula(cedula.value)"
            />
            <i
              *ngIf="!sending"
              class="fas fa-search"
              (click)="buscarCedula(cedula.value)"
            ></i>
            <div *ngIf="sending" id="loading"></div>
          </div>
          <p>Introduzca la cédula médica</p>

          <small *ngIf="!cedulaValida" class="text-danger"
            >Cédula médica no válida</small
          >

          <div class="results" *ngIf="cedulasEncontradas">
            <p style="margin: 25px 0px" class="text-bold">
              Resultados Obtenidos
            </p>

            <app-table-card-basic
              [tableHeaders]="cedulasTableHeaders"
              [rows]="arrayCedulas"
              class="cards-container"
              [name]="'grupo1'"
            >
            </app-table-card-basic>
          </div>
        </div>

        <button
          (click)="devolver()"
          class="btn btn-primary w-sm-100"
          style="flex: 1; padding: 0px"
        >
          Efectuar Devolución Administrativa
        </button>
      </div>
    </div>
    <div *ngIf="tabSelected == 'Facturas y XML'" class="container-body">
      <h1>Facturas y XML</h1>
    </div>
    <div *ngIf="tabSelected == 'Coberturas'" class="container-body">
      <h1>Coberturas</h1>
    </div>
    <div *ngIf="tabSelected == 'Tabulador'" class="container-body2">
      <div
        style="display: flex; width: 100%; align-content: center"
        class="titleContainer"
      >
        <div class="doc-head-item text-bold text-medium">
          Tabulador Quirúrgico
        </div>

        <div class="search-sm search-container" style="flex: 1">
          <div style="text-align: right; position: relative">
            <input
              class="form-control search-input"
              placeholder="Buscar..."
              style="max-width: 350px"
              type="text"
            />
            <i class="fas fa-search" style="top: 22px"></i>
          </div>
        </div>
      </div>

      <div>
        <app-table-basic
          [tableHeaders]="amparadasTableHeaders"
          [rows]="rowsAmparadas"
          class="table"
        ></app-table-basic>

        <app-table-card-basic
          [tableHeaders]="amparadasTableHeadersMobile"
          [rows]="rowsAmparadas"
          class="cards-container"
          [name]="'grupo1'"
          [type]="'quirurgico'"
        >
        </app-table-card-basic>
      </div>
      <div *ngIf="tabSelected == 'Información Bancaria'" class="container-body">
        <div class="doc-head-item text-bold text-medium">
          Información Bancaria Asociada al Trámite
        </div>
        <div class="content-info">
          <div class="fill" *ngFor="let fill of infoBancaria">
            <p>{{ fill.clave }}</p>
            <p>{{ fill.valor }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="tabSelected == 'Contacto'" class="container-body2">
        <h2>Información de Contacto</h2>
        <div style="display: flex; flex-wrap: wrap">
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Primer Nombre</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Segundo Nombre</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30">
            <label class="text-secondary">Primer Apellido</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Segundo Apellido</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-60">
            <label class="text-secondary">Domicilio</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Código Postal</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">País</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30">
            <label class="text-secondary">Estado</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Municipio</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Ciudad</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30">
            <label class="text-secondary">Colonia</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Teléfono 1</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30 mr-5pc">
            <label class="text-secondary">Teléfono 2</label>
            <input class="form-control" />
          </div>
          <div class="form-group contact-input-30">
            <label class="text-secondary">Correo Electrónico</label>
            <input class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="tabSelected == 'Información Bancaria'" class="container-body">
      <div style="width: 100%">
        <div class="doc-head-item text-bold text-medium">
          Información Bancaria Asociada al Trámite
        </div>
        <div class="content-info">
          <div class="fill" *ngFor="let fill of infoBancaria">
            <p>{{ fill.clave }}</p>
            <p class="text-bold">{{ fill.valor }}</p>
          </div>
        </div>
        <div style="margin-top: 50px">
          <div style="background-color: #eeeeee; padding: 16px 60px">
            <div>
              <p class="text-bold text-medium">
                Pesos MXN - 1245XXXXXXXXXXXXXXX5269
              </p>

              <p>BBVA (Tipo de Pago: Transferencia)</p>
            </div>
            <div style="margin-top: 25px">
              <div class="file-bank" *ngFor="let item of [1, 2, 3]">
                <p class="text-bold text-primary">Nombre de archivo</p>
                <div>
                  <i style="margin-right: 10px" class="fas fa-search"></i>
                  <i class="fas fa-download doc-action"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="tabSelected == 'Contacto'" class="container-body2">
      <div class="doc-head-item text-bold text-medium">
        Información de Contacto
      </div>

      <div style="display: flex; flex-wrap: wrap">
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Primer Nombre</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Segundo Nombre</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30">
          <label class="text-secondary">Primer Apellido</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Segundo Apellido</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-60">
          <label class="text-secondary">Domicilio</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Código Postal</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">País</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30">
          <label class="text-secondary">Estado</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Municipio</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Ciudad</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30">
          <label class="text-secondary">Colonia</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Teléfono 1</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30 mr-5pc">
          <label class="text-secondary">Teléfono 2</label>
          <input class="form-control" />
        </div>
        <div class="form-group contact-input-30">
          <label class="text-secondary">Correo Electrónico</label>
          <input class="form-control" />
        </div>
      </div>
    </div>
  </div>
  <ngx-smart-modal
    #confirmar
    [customClass]="'nsm-centered'"
    identifier="confirmar"
  >
    <div class="reasignar" style="padding: 20px; justify-content: space-around">
      <p class="text-bold mb-4" style="text-align: center">Confirmación</p>

      <div>
        <form action="">
          <div class="form-group">
            <p class="text-center">
              El usuario recibirá la notificación y el trámite quedará bloqueado
              hasta que éste haga los cambios correspondientes
            </p>
          </div>
          <div
            class="
              text-center
              flex-container
              container-horizontal
              mt-4
              flex-sm-revert
            "
          >
            <button (click)="closeModal()" class="btn btn-outline-gray mt-sm-2">
              Cancelar
            </button>
            <button (click)="desactivar()" class="btn btn-primary">
              Aceptar
            </button>
          </div>
        </form>
      </div>
    </div>
  </ngx-smart-modal>
  <ngx-smart-modal
    #registrar
    [customClass]="'nsm-centered'"
    identifier="registrar"
  >
    <div class="reasignar" style="padding: 20px">
      <p class="text-bold mb-4 text-center">Registrar Siniestro</p>

      <div>
        <form action="">
          <div class="form-group">
            <label>Tipo de Reclamación (*)</label>
            <select class="form-control">
              <option>Seleccione 1</option>
            </select>
          </div>
          <div class="form-group">
            <label>Tipo de trámite (*)</label>
            <select class="form-control">
              <option>Seleccione 1</option>
            </select>
          </div>
          <div class="form-group">
            <label>Causa (*)</label>
            <select class="form-control">
              <option>Seleccione 1</option>
            </select>
          </div>
          <div class="form-group">
            <label>Tipo de Red (*)</label>
            <select class="form-control">
              <option>Seleccione 1</option>
            </select>
          </div>
          <div class="form-group search-input">
            <label>Diagnóstico</label>
            <input class="form-control" placeholder="Diagnóstico" />
            <i class="fas fa-search"></i>
          </div>
          <div class="flex-container container-horizontal mt-2 flex-sm-revert">
            <button
              (click)="closeModal()"
              class="btn btn-outline-gray mt-sm-2 cancelar-button"
            >
              Cancelar
            </button>
            <button (click)="closeModal()" class="btn btn-primary">
              Aceptar
            </button>
          </div>
        </form>
      </div>
    </div>
  </ngx-smart-modal>
</div>
<div *ngIf="state == 2">
  <h5>Sin datos o mockup a mostrar</h5>
</div>
