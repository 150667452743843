import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { AppConstants } from 'src/app/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Input() options: Array<any>;
  @Input() service: any;
  @Input() title: string;
  activeOption: string;
  showDropdownBurger: boolean = false;
  showDropdown: boolean = false;

  constructor(public authService: AuthService, private router: Router) {
    this.options = [{ title: 'PRINCIPAL' }, { title: 'REPORTES' }];
  }

  ngOnInit(): void {
    this.activeOption = this.service?.selectedMenu;

    console.log(this.options);

    // this.selected.emit(this.options[0]);

    this.dropDownListenerBurger();
    this.dropDownListener();
  }

  switchDropdownBurger() {
    this.showDropdownBurger = !this.showDropdownBurger;
  }

  switchDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  dropDownListenerBurger() {
    document.addEventListener('click', (evt) => {
      const dropdown = document.getElementById('dropdownBurger');
      let targetElement: any = evt.target;

      if (targetElement.closest('.burger-menu')) return;

      do {
        if (targetElement == dropdown) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      // console.log("Clicked outside!");
      this.showDropdownBurger = false;
    });
  }

  dropDownListener() {
    document.addEventListener('click', (evt) => {
      const dropdown = document.getElementById('dropdown');
      let targetElement: any = evt.target;

      if (targetElement.closest('.responsive-menu')) return;

      do {
        if (targetElement == dropdown) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      // console.log('Clicked outside!');
      this.showDropdown = false;
    });
  }

  singOut() {
    this.showDropdown = false;

    this.authService.logout().subscribe(
      (response) => {
        console.log(response);
        localStorage.removeItem('userToken');
        this.router.navigate(['/']);
      },
      (error) => {
        console.log(error);
        localStorage.removeItem('userToken');
        this.router.navigate(['/']);
      }
    );
  }
}
