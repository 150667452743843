import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { MonitorService } from '../monitor.service';
import { ReembolsoService } from '../reembolso/reembolso.service';
import { AppConstants } from 'src/app/constants';

@Component({
  selector: 'app-catalogos-se',
  templateUrl: './catalogos-se.component.html',
  styleUrls: ['./catalogos-se.component.scss'],
})
export class CatalogosSeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    public monitorService: MonitorService,
    public route: Router,
    public reembolsoService: ReembolsoService
  ) {
    this._baseURL = AppConstants.apiBienestar;
  }

  form: FormGroup;
  waitingForApi = false;
  _baseURL;
  file;
  errorMsg = null;
  confirmMsg = null;
  arrayAreas = [];
  arrayCatalogos = [];
  arrayDocs = [
    {
      status: 'OBLIGATORIO',
      colorStatus: '#7ebe25',
      fileInfo: {
        name: 'Cargar/Descargar plantilla',
        url: '',
      },
      actions: {
        motivo: false,
        validar: false,
        ver: false,
        descargar: true,
        expandir: false,
        subir: true,
      },
    },
  ];

  @ViewChild('uploader') public uploader;

  ngOnInit(): void {
    this.reembolsoService.catalogosSE().subscribe((res) => {
      this.arrayAreas = [...res.areas];
    });

    this.form = this.fb.group({
      area: [null, Validators.required],
      catalogo: [null, Validators.required],
      file: [null, Validators.required],
    });
  }
  get f() {
    return this.form.controls;
  }

  guardar() {
    const catalogo = this.f.catalogo.value;
    if (!catalogo) {
      this.errorMsg = 'Debe seleccionar un catálogo';
      return;
    }

    this.errorMsg = null;
    this.waitingForApi = true;
    this.reembolsoService.subirCatalogoSE(catalogo, this.file).subscribe(
      (res) => {
        this.waitingForApi = false;
        this.confirmMsg = res.message;
        this.ngxSmartModalService.open('confirmCatalogCreaded');
      },
      (err) => {
        this.errorMsg = err.error.message;
        this.waitingForApi = false;
      }
    );
  }

  setCatalogos() {
    const area = this.arrayAreas.find((e) => e.code == this.f.area.value);

    if (area) this.arrayCatalogos = [...area.catalogos];
    else this.arrayCatalogos = [];

    this.f.catalogo.setValue(null);
  }

  fileManagerEvent(e, index) {
    this.errorMsg = null;
    switch (e.action) {
      case 'descargar':
        this.descargarPlantilla();
        break;

      case 'subir':
        var fileExt = e.data.name.split('.').pop();

        if (
          !fileExt.toString().includes('xls') &&
          !fileExt.toString().includes('xlsx')
        ) {
          this.errorMsg = 'Formato de archivo inválido';
          return;
        }

        this.file = e.data;
        this.f.file.setValue(true);

        break;

      default:
        break;
    }
  }

  descargarPlantilla() {
    const catalogo = this.f.catalogo.value;

    if (catalogo) {
      // this.waitingForApi = true;
      const url = `${this._baseURL}/v1/siniestro-estandar/${catalogo}/plantilla`;
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          Authorization: this.reembolsoService.getToken(),
          Accept: '*/*',
        }),
      }).then((response) => {
        let fileName = response.headers
          .get('content-disposition')
          .replace('attachment;filename=', '');

        if (response.ok)
          response.blob().then((blob) => {
            // this.waitingForApi = false;
            var file = new Blob([blob], { type: 'application/vnd.ms-excel' });
            var fileURL = URL.createObjectURL(file);
            var link = document.createElement('a');
            link.href = fileURL;
            link.download = fileName;
            link.click();
          });
        else {
          // this.waitingForApi = false;
        }
      });
    } else this.errorMsg = 'Debe seleccionar un catálogo';
  }

  finish() {
    this.errorMsg = null;
    this.confirmMsg = null;
    this.form.reset();
    this.ngxSmartModalService.close('confirmCatalogCreaded');
  }
}
