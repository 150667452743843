<header class="header">
  <div class="logo">
    <img src="assets/img/LogoSURA.svg" alt="" width="130" />
  </div>

  <div class="navbar">
    <div
      *ngFor="let item of menu"
      [ngClass]="{ active: item == selected }"
      class="menu-item text-medium"
      (click)="changeSelected(item)"
    >
      <span>{{ item.menu }}</span>
      <div class="submenu text-small text-body">
        <div *ngFor="let submenu of item.subMenu" class="submenu-item">
          {{ submenu }}
        </div>
      </div>
    </div>
    <div class="user">
      <img alt="" src="assets/img/user.png" (click)="switchDropdown()" />
      <div class="dropdown" id="dropdown" [ngClass]="{ active: showDropdown }">
        <p (click)="singOut()">Cerrar Sesión</p>
      </div>
    </div>
  </div>
</header>
