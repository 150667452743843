import { Component, OnInit } from '@angular/core';
import { MonitorService } from '../monitor.service';
import { VidaService } from './vida.service';

@Component({
  selector: 'app-vida',
  templateUrl: './vida.component.html',
  styleUrls: ['./vida.component.scss'],
})
export class VidaComponent implements OnInit {
  constructor(
    public vidaServ: VidaService,
    public monitorService: MonitorService
  ) {}

  ngOnInit(): void {}
}
