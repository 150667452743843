import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';
import { ApiBienestarService } from 'projects/bienestar/src/app/service/api-bienestar.service';

@Component({
  selector: 'app-portal-seguimiento-monitor',
  templateUrl: './portal-seguimiento.component.html',
  styleUrls: ['./portal-seguimiento.component.scss'],
})
export class PortalSeguimientoComponent implements OnInit {
  @Input() service: any;
  @Input() breadcrumbs: Array<any>;
  table: Array<string>;
  backgroundImage: string;
  state: number = 1;
  arrayTest: Array<number>;
  headers: any;
  data: any;
  dataTimeLine: Array<any>;
  typeTimeLine: Array<string>;
  actualizado: string;
  profileData: Array<any>;
  typeProcess: string;
  nmFolio: any;
  desactivado: boolean = false;
  @Input() tramite: any;
  tabSelected: string;
  arrayDocsUpdated: any = [];
  infoBancaria: Array<any> = [
    {
      clave: 'RFC',
      valor: '12345678',
    },
    {
      clave: 'Teléfono 1',
      valor: '+52 55 5211 4004',
    },
    {
      clave: 'Nombre Completo',
      valor: 'Roque David Montenegro Suarex',
    },
    {
      clave: 'Teléfono 2',
      valor: '+52 55 5211 4004',
    },
    {
      clave: 'Nacionalidad',
      valor: 'Mesicano',
    },
    {
      clave: 'Correo Electrónico',
      valor: 'mail@mail.com',
    },
    {
      clave: 'Sexo',
      valor: 'Masculino',
    },
    {
      clave: '',
      valor: '',
    },
    {
      clave: 'Domicilio',
      valor:
        'Calle Balderas #27, Centro Historico Ciudad de Mexico, Del. Cuauhtémo, C.P  Mexico',
    },
  ];
  arrayDocsAsegurados: any = [
    {
      nombre: 'Informe Medico',
    },
    {
      nombre: 'Aviso Accide.',
    },
    {
      nombre: 'Compr. Dom.',
    },
    {
      nombre: 'Otro Doc.',
    },
  ];

  amparadasTableHeaders: any;
  amparadasTableHeadersMobile: any;
  rowsAmparadas: any;
  cedulaValida: boolean = true;
  cedulasEncontradas: boolean = false;
  sending: boolean = false;
  arrayCedulas: any = [];

  cedulasTableHeaders: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private _route: ActivatedRoute,
    private _apiBcService: ApiBienestarService
  ) {
    this.tabSelected = 'General';
    this.actualizado = '03/03/2021';
    this.dataTimeLine = [
      '04/04/2021',
      'DD/MM/YY',
      'DD/MM/YY',
      'Devuelto Administrativamente',
    ];
    this.typeTimeLine = ['info', 'warning', 'danger'];
    this.typeProcess = 'Reembolso General';
    this.arrayTest = [0, 1, 2, 3, 4];
    this.backgroundImage = 'assets/img/fondo-auth.jpeg';
    this.headers = [
      { title: 'Asegurado', value: 'name', mobile: true },
      { title: 'Edad', value: 'age', mobile: true },
      { title: 'Póliza', value: 'poliza', mobile: true },
      { title: 'Certificado', value: 'cert', mobile: true },
      {
        title: 'Suma Asegurada',
        value: 'sureAmount',
        mobile: true,
        prefix: '#',
      },
      {
        title: 'Suma Asegurada Disponible',
        value: 'sureAvail',
        mobile: true,
        prefix: '#',
      },
      { title: 'Número de Siniestro', value: 'number', mobile: true },
    ];
    this.data = {
      name: 'Sofia Orrostieta Vasquez',
      age: 50,
      poliza: '73O612547',
      cert: 1,
      sureAmount: 1220000,
      sureAvail: 1220000,
      number: 0,
    };
    this.table = [].constructor(30);
  }

  ngOnInit(): void {
    this.service.setSelectedMenu('Mis Trámites');
    this._route.paramMap.subscribe((params) => {
      this.nmFolio = params.get('folio');

      console.log(this.nmFolio);
      if (!this.nmFolio) {
        this.nmFolio = 697425;
      }
      //
      // this._apiBcService.getPortal(this.nmFolio).subscribe((response) => {
      //   console.log(response);
      //   this.datosPortal = response;
      //   this.data = [response.grupo_tramite, this.nmFolio, response.nmsiniestro];
      //   this.dataTimeLine = [response.fecha, 'DD/MM/YY', 'DD/MM/YY', 'Devuelto Administrativamente'];
      // });
    });

    this.amparadasTableHeaders = [
      {
        name: 'codigo',
        label: 'CPT - Código de Procedimiento',
      },
      {
        name: 'desc',
        label: 'Descripción - Sistema Tegumentario',
      },
      {
        name: 'pct',
        label: '% Correspondiente al Cirujano',
      },
    ];

    this.amparadasTableHeadersMobile = [
      {
        name: 'codigo',
        label: 'CPT - Código de Procedimiento',
      },
      {
        name: 'desc',
        label: 'Descripción - Sistema Tegumentario',
      },
      {
        name: 'pct',
        label: '% Correspondiente al Cirujano',
      },
    ];

    this.cedulasTableHeaders = [
      {
        name: 'nombre',
        label: 'Nombres y Apellidos',
      },
      {
        name: 'especialidad',
        label: 'Especialidad Médica',
      },
    ];

    this.fillTableAmparadas();
  }

  closeModal() {
    this.ngxSmartModalService.getModal('confirmar').close();
    this.ngxSmartModalService.getModal('registrar').close();
  }

  openFile() {}

  getFile(index) {
    this.arrayDocsUpdated.push(...this.arrayDocsAsegurados.splice(index, 1));
  }

  devolver() {
    this.ngxSmartModalService.getModal('confirmar').open();
  }

  nuevoTramite() {
    this.ngxSmartModalService.getModal('registrar').open();
  }

  desactivar() {
    this.desactivado = true;
    this.ngxSmartModalService.getModal('confirmar').close();
  }

  fillTableAmparadas() {
    this.rowsAmparadas = [
      {
        codigo: 'Gastos medicos',
        desc: '4800',
        pct: '10',
      },
      {
        codigo: 'Gastos medicos',
        desc: '4800',
        pct: '10',
      },
      {
        codigo: 'Gastos medicos',
        desc: '4800',
        pct: '10',
      },
    ];
  }

  buscarCedula(c) {
    this.arrayCedulas = [];
    if (!this.sending) {
      if (c === '') {
        this.cedulaValida = false;
        this.cedulasEncontradas = false;
        return;
      }

      this.sending = true;
      this.cedulaValida = true;
      this._apiBcService.getCedula(c).subscribe(
        (response) => {
          for (let index = 0; index < response.length; index++) {
            const element = response[index];

            this.arrayCedulas.push({
              nombre: element.nombre + ' ' + element.paterno,
              especialidad: element.titulo,
            });
          }

          this.sending = false;
          this.cedulasEncontradas = true;
        },
        (error) => {
          this.cedulasEncontradas = false;
          this.sending = false;
        },
        () => {}
      );
    }
  }
}
