<!-- <app-nav-bar-monitor [menu]="menu"></app-nav-bar-monitor> -->
<!-- <app-nav-bar-new [menu]="menu"></app-nav-bar-new> -->

<global-header [title]="'SISTEMA DE GESTIÓN DE SINIESTROS'"></global-header>

<navbar-blue [menu]="monitorService.menu"> </navbar-blue>

<router-outlet></router-outlet>

<footer-sura></footer-sura>
