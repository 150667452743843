import { AlianzaComponent } from './alianza/alianza.component';
import { VidaComponent } from './vida/vida.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuGuard } from './guards/menu.guard';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';
import { ConfiguradorTramiteComponent } from './configurador-tramite/configurador-tramite.component';
import { SiniestroEstandarComponent } from './siniestro-estandar/siniestro-estandar.component';
import { PreviewPdfComponent } from 'src/app/shared/preview-pdf/preview-pdf.component';
import { CatalogosSeComponent } from './catalogos-se/catalogos-se.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'error',
    component: ErrorComponent,
  },

  {
    path: 'home',
    component: HomeComponent,
  },

  {
    path: 'preview-pdf',
    component: PreviewPdfComponent,
  },

  {
    path: 'vida',
    component: VidaComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./vida/vida.module').then((m) => m.VidaModule),
        canActivate: [MenuGuard],
        data: ['Vida'],
      },
    ],
  },

  {
    path: 'cristales',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./cristales/cristales.module').then((m) => m.CristalesModule),
        canActivate: [MenuGuard],
        data: ['Cristales'],
      },
    ],
  },

  {
    path: 'reembolso',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./reembolso/reembolso.module').then((m) => m.ReembolsoModule),
        canActivate: [MenuGuard],
        data: ['GMM'],
      },
    ],
  },

  {
    path: 'cirugia',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./cirugia/cirugia.module').then((m) => m.CirugiaModule),
        canActivate: [MenuGuard],
        data: ['GMM'],
      },
    ],
  },

  {
    path: 'gmm',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./reembolso/reembolso.module').then((m) => m.ReembolsoModule),
        canActivate: [MenuGuard],
        data: ['GMM'],
      },
    ],
  },

  {
    path: 'alianza',
    component: AlianzaComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./alianza/alianza.module').then((m) => m.AlianzaModule),
        canActivate: [MenuGuard],
        data: ['Alianza'],
      },
    ],
  },

  {
    path: 'alianzas',
    component: AlianzaComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./alianza/alianza.module').then((m) => m.AlianzaModule),
        canActivate: [MenuGuard],
        data: ['Alianzas'],
      },
    ],
  },

  {
    path: 'cabina',
    loadChildren: () =>
      import('./cabina/cabina.module').then((m) => m.CabinaModule),
    canActivate: [MenuGuard],
    data: ['Cristales', 'Cabina'],
  },

  {
    path: 'configuracion/reglas-de-asignacion',
    loadChildren: () =>
      import('./reglas/reglas.module').then((m) => m.ReglasModule),
    canActivate: [MenuGuard],
    data: ['Reglas'],
  },

  {
    path: 'configuracion/configurador-de-tramites',
    component: ConfiguradorTramiteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./configurador-tramite/configurador-tramite.module').then(
            (m) => m.ConfiguradorTramiteModule
          ),
      },
    ],
  },

  {
    path: 'configuracion/reglas-se',
    component: SiniestroEstandarComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./siniestro-estandar/siniestro-estandar.module').then(
            (m) => m.SiniestroEstandarModule
          ),
      },
    ],
  },

  {
    path: 'configuracion/catalogos-se',
    component: CatalogosSeComponent,
  },

  {
    path: 'login',
    redirectTo: '',
    pathMatch: 'full',
  },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
